import Modal from '../Modal'
import SnappySlider from '../SnappySlider'
import VerticalSnappySlide from '../SnappySlider/VerticalSnappySlide'
import Template from '../Template'
import DogSitter1 from './dog-sitter-1.png'
import * as style from './style.module.scss'
import { navigate } from 'gatsby'
import Link from 'gatsby-link'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useDialogState, DialogDisclosure } from 'reakit/Dialog'

const PageForDogOwners = (props) => {
  function SignUpModal() {
    const signUp = useDialogState({ baseId: 'signUp' })

    return (
      <>
        <DialogDisclosure className={style.buttonSubmit} {...signUp}>
          Find a dog sitter
        </DialogDisclosure>
        <Modal {...signUp}>
          <div className={style.modal}>
            <div>
              <div className={style.title}>
                <h2>We'll be live soon!</h2>
                <button
                  className={style.buttonClose}
                  onClick={() => signUp.hide()}
                >
                  Close
                </button>
              </div>
              <p>Sorry, but we're not live yet 😭</p>
              <p>
                Looking for the best local pet care? Add your email address and
                we'll let you know as soon as we launch!
              </p>
              <form
                action='https://app.loops.so/api/newsletter-form/clmusgt0c0185l40oqj3wbd3s'
                method='post'
                onSubmit={async (event) => {
                  event.preventDefault()
                  const response = await fetch(event.target.action, {
                    method: event.target.method,
                    body: new URLSearchParams(new FormData(event.target)),
                  })

                  const result = await response.json()

                  if (result.success) {
                    navigate('/thank-you-for-signing-up')
                    return
                  }

                  alert(result)
                }}
              >
                <div>
                  <label htmlFor={'email'}>Email address</label>
                  <input type='email' name='email' required />
                  <input
                    type='hidden'
                    name='userGroup'
                    value='For dog owners - signup'
                  />
                </div>
                <div>
                  <button type='submit'>Sign up for the waitlist</button>
                </div>
              </form>
            </div>
            <p className={style.note}>
              By signing up, you agree to receive emails from us. We'll only
              send you emails related to doggos.com and your account.
            </p>
          </div>
        </Modal>
      </>
    )
  }

  return (
    <Template>
      <Helmet>
        <title>For dog owners - {props.data.site.siteMetadata.title}</title>
      </Helmet>
      <div className={style.heroContainer}>
        <h1>The safest way to find a dog sitter</h1>
        <p className={style.subline}>
          Learn about the safety features for pet owners
        </p>
      </div>

      <div className={style.trust}>
        <div className={style.copy}>
          <h2>Trusting someone with your dog is hard</h2>
          <p>
            <strong>We're making it easier</strong>
          </p>
          <p>
            In-home dog sitting is great, but as dog owners ourselves, we
            recognize how hard it is to leave your dog with someone new.
          </p>
          <p>
            We wanted more from a platform. Like rules for who can join,
            realistic limits on how many dogs can be watched at once, required
            daily updates, and active moderation.
          </p>
          <p>...so, we built it ourselves.</p>
        </div>
        <div>
          <img className={style.coverPhoto} src={DogSitter1}></img>
        </div>
      </div>

      <div className={style.benefits}>
        <h2>Benefits for pet owners</h2>
        <p className={style.subline}>What makes us different</p>
        <div className={style.textBlockContainer}>
          <div className={style.textBlock}>
            <h3 className={style.h3}>The highest standards for sitters</h3>
            <p>
              In addition to a background check, drivers license & age
              verification, our multi-step application process includes a video
              call and live knowledge test with doggos.com staff.
            </p>
            <Link className={style.link} to={'/the-sitter-application-process'}>
              Read about the sitter application process
            </Link>
          </div>
          <div className={style.textBlock}>
            <h3 className={style.h3}>
              The lowest fees for pet owners of any platform
            </h3>
            <p>
              Not only are we faster and better than the other platforms, we're
              cheaper, too. On average, pet owners save more than 20% on the
              cost of dog sitting when using doggos.com, due to our
              industry-lowest transaction fee of only 10%.
            </p>
          </div>
          <div className={style.textBlock}>
            <h3 className={style.h3}>Active moderation</h3>
            <p>
              We actively monitor our search page and all dog sitters for
              account activity that is against our platform rules, and have
              sophisticated controls for blocking, limiting or de-activating
              accounts who are not in compliance.
            </p>
          </div>
        </div>
      </div>

      <div className={style.snappySlider}>
        <div className={style.sliderCopy}>
          <h2>The best sitters use doggos.com</h2>
          <p className={style.subline}>
            We believe that all dogs deserve quality care, not that everyone can
            be a dog sitter
          </p>
        </div>
        <SnappySlider>
          <VerticalSnappySlide
            title='1. We find the best local sitters'
            subline='We seek out highly-rated local dog sitters and businesses to ensure we allow only the best pet care providers onto the platform.'
          />
          <VerticalSnappySlide
            title='2. Screening & license verification'
            subline='In addition to our video interview, we require background checks for every pet caregiver and an active business license for those advertising as professionals.'
          />
          <VerticalSnappySlide
            title='3. Platform rules and moderation'
            subline='We require pet caregivers abide by our platform rules, such as watching no more than 3 dogs at a time, and agreeing to send daily photo/video updates.'
          />
        </SnappySlider>
      </div>
      <div className={style.buttonContainer}>
        <Link className={style.button} to='/doggos-vs-rover-vs-wag'>
          Learn more about us
        </Link>
        <div className={style.buttonSearch}>
          <SignUpModal />
        </div>
      </div>
    </Template>
  )
}
export default PageForDogOwners
